<template>
    <div class="container">
        <div class="fl-x-cc pb-4">
            <h4 class="text-seventh"> Disease & Treatments</h4>
        </div>
        <div class="row mt-4">
            <div class="col-lg-3 col-6">
                <div @click="redirectToIndividualPage( catIds.horse)">
                    <img src="../../../assets/img/elemn2/horse.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Horse</p>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div @click="redirectToIndividualPage( catIds['goat and sheep'])">
                    <img src="../../../assets/img/elemn2/goat.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Goat</p>
                </div>
            </div>

            <div class="col-lg-3 col-6">
                <div @click="redirectToIndividualPage( catIds.pigs)">
                    <img src="../../../assets/img/elemn2/pig.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Pig</p>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div @click="redirectToIndividualPage( catIds['poultry'])">
                    <img src="../../../assets/img/elemn2/poultry.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Poultry</p>
                </div>
            </div>
<!--            <div class="col-lg-3 pt-3">-->
<!--                <div @click="redirectToIndividualPage( catIds['goat and sheep'])">-->
<!--                    <img src="../../../assets/img/rabbit.png" class="img-fluid h-4r">-->
<!--                    <p class="font-proxima-bold ml-3 text-secondary">Rabbit</p>-->
<!--                </div>-->
<!--            </div>-->
            <div class="col-lg-3 pt-3 col-6">
                <div @click="redirectToIndividualPage( catIds['cattle'])">
                    <img src="../../../assets/img/elemn2/cow.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Cattle</p>
                </div>
            </div>
            <div class="col-lg-3 pt-3 col-6">
                <div @click="redirectToIndividualPage( catIds['cats'])">
                    <img src="../../../assets/img/elemn2/cat.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Cats</p>
                </div>
            </div>
            <div class="col-lg-3 pt-3 col-6">
                <div @click="redirectToIndividualPage( catIds['dogs'])">
                    <img src="../../../assets/img/elemn2/dog.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Dogs</p>
                </div>
            </div>
<!--            <div class="col-lg-3 pt-3 col-6">-->
<!--                <div @click="$router.push('/user/home/')">-->
<!--                    <img src="../../../assets/img/exotic.png" class="img-fluid h-4r">-->
<!--                    <p class="font-proxima-bold ml-3 text-secondary">Exotic</p>-->
<!--                </div>-->
<!--            </div>-->

        </div>
        <gallery-images-swiper :ads="banners"></gallery-images-swiper>
        <modal v-if="modalStatus" ref="UserSubscriptionModal" title=" " width="60r" show-additional-buttons>
<!--            <template #buttons>
        <span @click="closeSubscriptionModal" class="mu-p">
          <i class="fa fa-close"></i></span>
            </template>-->
            <user-subscription-plans/>
        </modal>
    </div>
</template>

<script>
import GalleryImagesSwiper from '@components/user-home/Swipper';
import urls from '../../../data/urls';
import axios from 'secure-axios';
import store from '../../../store';
import UserSubscriptionPlans from '../user-payment/UserSubscriptionPlans';

export default {
    name: 'AnimalPage',
    components: { UserSubscriptionPlans, GalleryImagesSwiper },
    data () {
        return {
            modalStatus: true,
            bannersUrl: urls.userHome.banners.list,
            banners: '',
            catIds: ''
        };
    },
    mounted () {
        this.$refs.UserSubscriptionModal.show();
        this.userDataFetch();
    },
    methods: {
        closeSubscriptionModal () {
            this.$refs.UserSubscriptionModal.close();
        },
        userDataFetch () {
            const vip = store.getters.currentUser.vip_member;
            if (vip) {
                this.modalStatus = false;
            } else {
                this.modalStatus = true;
            }
            this.LoadData();
            console.log('vip', vip);
        },

        async LoadData () {
            console.log('LoadData');
            const response = await axios.get(this.bannersUrl);
            const result = await response.data;
            // console.log('banners data', response.data);
            this.banners = result.ads;
            this.catIds = result.cat_ids;
        },
        redirectToIndividualPage (idx) {
            this.$router.push('/disease/individual-species/' + idx);
        }
    }
};
</script>

<style scoped>

</style>
