<template>
    <main>
        <div class=" py-5">

            <div class="row">
                <div class="col-12">
                    <swiper class="swiper" :options="swiperOption" >
                        <swiper-slide class="h-50vh bg bg-cover bg-pos-c" :style="{ 'background-image': 'url(' + item.image + ')'}"  v-for="(item,index) in ads" :key="index">
                            <a class="row h-100p" :href="item.url" target="_blank">
                                <div class="col-12 col-lg-12 singleFolio">
                                    <div class="">
<!--                                        <img-->
<!--                                            src="../../assets/user-home/bull1.png"-->
<!--                                            class="slider-img  h-50vh w-50v" alt="">-->
<!--                                        <a :href="item.url">-->
<!--                                            <img-->
<!--                                            :src="item.image"-->
<!--                                            class="slider-img pl-lg-7" alt="">-->
<!--                                        </a>-->
                                    </div>
                                </div>
                            </a>
                        </swiper-slide>
<!--                        <swiper-slide>-->
<!--                            <div class="row">-->
<!--                                <div class="col-12 col-lg-12 singleFolio">-->
<!--                                    <div class="">-->
<!--                                        <img-->
<!--                                            src="../../assets/user-home/bull1.png"-->
<!--                                            class="slider-img h-50vh w-50v" alt="">-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </swiper-slide>-->
                        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                    </swiper>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: 'TwoImageCarousel',
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        ads: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            // ads: [
            //     {
            //         id: 115,
            //         title: 'test avertisement 12-06-2024',
            //         image: '/vet/media/animal2.jpg',
            //         url: 'https://www.google.com/',
            //         position: 'top',
            //         position_number: 1
            //     },
            //     {
            //         id: 28,
            //         title: 'doctor for doctors',
            //         image: '/vet/media/doctor_Xlt4bCy.png',
            //         url: '',
            //         position: 'bottom',
            //         position_number: 1
            //     },
            //     {
            //         id: 20,
            //         title: 'education purpose',
            //         image: '/vet/media/education_uRrpOhY.png',
            //         url: '',
            //         position: 'bottom',
            //         position_number: 2
            //     },
            //     {
            //         id: 124,
            //         title: 'test',
            //         image: '/vet/media/squ3_rOA4cld.jpeg',
            //         url: 'https://www.google.com/',
            //         position: 'top',
            //         position_number: 2
            //     },
            //     {
            //         id: 126,
            //         title: 'Title test',
            //         image: '/vet/media/Screenshot_2023-03-19_134909.png',
            //         url: '',
            //         position: 'top',
            //         position_number: 3
            //     }
            // ],
            // swiperOption: {
            //     navigation: {
            //         nextEl: '.swiper-button-next',
            //         prevEl: '.swiper-button-prev'
            //     }
            // }
            swiperOption: {
                autoHeight: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: { el: '.swiper-pagination', clickable: true },
                mousewheel: false,
                parallax: false,
                centeredSlides: true,
                // autoplay: true,
                autoplay: { delay: 8000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: { speed: 1500 },
                    1024: { slidesPerView: 1, spaceBetween: 0, speed: 1000 },
                    768: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }
            }

        };
    }
};
</script>

<style lang="scss" scoped>

//.slider-img {
//    width: 80%;
//    height: auto;
//}
.slider-img {
    max-width: 100%;
    height: auto;
}

.text-md {
    font-size: 1.4rem;
}

.singleFolio {
    width: 100%;
    height: auto;
    margin-bottom: 0%;
    position: relative;
    z-index: 2;

    .folioHover {
        //box-shadow: 0 8px 8px rgb(0, 0, 0/60%);
        bottom: 0px;
        width: 90%;
        height: 215px;
        position: absolute;
        background: #FFF;
        bottom: 0px;
        padding-left: 30px;
        transition: all ease 400ms;
        -moz-transition: all ease 400ms;
        -webkit-transition: all ease 400ms;
        -ms-transition: all ease 400ms;
        -o-transition: all ease 400ms;
        opacity: 0;
        visibility: hidden;
        z-index: 10;

        &.hover-left {
            left: 0;
        }

        &.hover-right {
            right: 0;
        }
    }

    &:hover {
        .folioHover {
            opacity: 1;
            visibility: visible;
            bottom: 60px;
        }
    }
}

.swiper-button-prev {
    color: var(--color-danger) !important;
}

.swiper-button-next {
    color: var(--color-danger) !important;
}

//.swiper-button-next::after{
//    position: absolute;
//    content: "";
//    background: url("../../assets/img/rlRight.png");
//}

//.folioHover {
//    //box-shadow: 0 8px 8px rgb(0, 0, 0/60%);
//    bottom: 0px;
//    width: 418px;
//    height: 215px;
//    position: absolute;
//    background: #FFF;
//    bottom: 0px;
//    padding-left: 30px;
//    transition: all ease 400ms;
//    -moz-transition: all ease 400ms;
//    -webkit-transition: all ease 400ms;
//    -ms-transition: all ease 400ms;
//    -o-transition: all ease 400ms;
//    opacity: 0;
//    visibility: hidden;
//    z-index: 10;
//}
//
//.folioHover {
//    width: 90%;
//    left: 0;
//}
</style>
